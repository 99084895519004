import React from 'react'

import './style.scss'

const SEOContent = ({ content }) => {
  if (!content) return ''
  return (
    <div className="seo-content">
      <div className="container">
        <span>“</span>
        <p>{content}</p>
        <span>”</span>
      </div>
    </div>
  )
}

export default SEOContent
