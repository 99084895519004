import React, { Component } from 'react'

import './style.scss'

export default class TextValueProp extends Component {
  render() {
    const { watName1 } = this.props
    return (
      <div className="text-prop">
        <div className="text-box">
          <h2 className="title-text-prop">สั่งพวงหรีด<span>{watName1 ? watName1 : 'แทนใจ'}</span></h2>
          <p>
            ไว้อาลัยครั้งสุดท้ายเลือกพวงหรีด ‘แทนใจ’ <span>ให้บุคคลสำคัญ</span>
          </p>
        </div>
        <div className="img-box">
          <img
            alt="แทนใจยินดีให้บริการ"
            src="https://d3u85r51ph21nf.cloudfront.net/static/girl_bow.png"
          />
        </div>
      </div>
    )
  }
}
