import React, { Component } from 'react'
import Scroll from 'react-scroll'
import Fade from 'react-reveal/Fade'

import './style.scss'

const scroller = Scroll.scroller
export default class ProductCategory extends Component {
  onScrollTo = ele => {
    const offset = document.getElementById('navbar').offsetHeight
    scroller.scrollTo(ele, {
      duration: 1500,
      delay: 100,
      smooth: 'easeInOutQuad',
      offset: -offset // Scrolls to element -155 pixels up the page (minus for fix main menu) // FIXME: refactor to get heigth of the header instead
    })
  }

  render() {
    const { aPriceRange, bPriceRange, fPriceRange } = this.props
    return (
      <div className="product-category">
        <h4>
          <span>ประเภทพวงหรีด</span>
        </h4>
        <div className="category-wrapper">
          <Fade top delay={100}>
            <div className="cat-item" onClick={() => this.onScrollTo('productA')}>
              <img
                className="cat-bg img-responsive"
                src="https://d3u85r51ph21nf.cloudfront.net/static/product_catA.png"
              />
              <p className="cat-text">
                พวงหรีดดอกไม้ราคาประหยัด <br />
                <span>{aPriceRange}</span>
              </p>
            </div>
          </Fade>
          <Fade top delay={300}>
            <div className="cat-item" onClick={() => this.onScrollTo('productB')}>
              <img
                className="cat-bg img-responsive"
                src="https://d3u85r51ph21nf.cloudfront.net/static/product_catB.png"
              />
              <p className="cat-text cat-b">
                พวงหรีดดอกไม้มาตรฐาน <br />
                <span>
                  {bPriceRange}{' '}
                  <img src="https://d3u85r51ph21nf.cloudfront.net/static/icon_hot.png" />
                </span>
              </p>
            </div>
          </Fade>
          <Fade top delay={500}>
            <div className="cat-item" onClick={() => this.onScrollTo('productPremium')}>
              <img
                className="cat-bg img-responsive"
                src="https://d3u85r51ph21nf.cloudfront.net/static/product_catP.png"
              />
              <p className="cat-text">
                พวงหรีดดอกไม้พรีเมียม <br />
                <span>2,990฿+</span>
              </p>
            </div>
          </Fade>
          <Fade top delay={700}>
            <div className="cat-item" onClick={() => this.onScrollTo('productF')}>
              <img
                className="cat-bg cat-f img-responsive"
                src="https://d3u85r51ph21nf.cloudfront.net/static/product_catF_v3.png"
              />
              <p className="cat-text cat-f">
                พวงหรีดพัดลม <br />
                <span>{fPriceRange}</span>
              </p>
            </div>
          </Fade>
          <Fade top delay={900}>
            <a
              className="cat-item"
              href="https://lin.ee/6Bs20SI?tanjai=B5"
              target="_blank"
              rel="noopener noreferrer"
              alt="@tanjai.co"
            >
              <img
                className="cat-bg cat-u img-responsive"
                src="https://d3u85r51ph21nf.cloudfront.net/static/product_catU_v1.png"
              />
              <p className="cat-text cat-u">
                พวงหรีดของใช้ <br />
                <span>1,190฿+</span>
              </p>
            </a>
          </Fade>
        </div>
      </div>
    )
  }
}
