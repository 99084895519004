import React, { Component } from 'react'
import Fade from 'react-reveal/Fade'
import Scroll from 'react-scroll'
import Tada from 'react-reveal/Tada'
import './style.scss'
import styled from 'styled-components'
import { Badge } from 'antd'

const scroller = Scroll.scroller
export default class Menu extends Component {
  onScrollTo = ele => {
    const offset = document.getElementById('mobile-menu').offsetHeight
    scroller.scrollTo(ele, {
      duration: 1500,
      // delay: 100,
      smooth: 'easeInOutQuad',
      offset: -offset // Scrolls to element -155 pixels up the page (minus for fix main menu) // FIXME: refactor to get heigth of the header instead
    })
  }

  render() {
    const { onClickMenu, activeMenu, isStickyMenuMobile } = this.props
    if (isStickyMenuMobile)
      return (
        <Fade duration={1000}>
          <div
            className={`mobile slide-menu ${isStickyMenuMobile ? 'sticky' : ''}`}
            id="mobile-menu"
          >
            <div
              className="slide-menu-item cat-a"
              onClick={() => {
                onClickMenu('cat-a')
                this.onScrollTo('productA')
              }}
            >
              <Tada when={activeMenu === 'cat-a'}>
                <p className={`${activeMenu === 'cat-a' ? 'active' : ''}`}>
                  <span className="font-cat">
                    <span>พวงหรีด</span>
                    <br />
                    ประหยัด
                  </span>
                </p>
              </Tada>
            </div>
            <div
              className="slide-menu-item cat-b"
              onClick={() => {
                onClickMenu('cat-b')
                this.onScrollTo('productB')
              }}
            >
              <Tada when={activeMenu === 'cat-b'}>
                <Badge.Ribbon text={'HOT!'}>
                  <p className={`${activeMenu === 'cat-b' ? 'active' : ''}`}>
                    <span className="font-cat">
                      <span>พวงหรีด</span>
                      <br />
                      มาตรฐาน
                    </span>
                  </p>
                </Badge.Ribbon>
              </Tada>
            </div>
            <div
              className="slide-menu-item cat-p"
              onClick={() => {
                onClickMenu('cat-p')
                this.onScrollTo('productPremium')
              }}
            >
              <Tada when={activeMenu === 'cat-p'}>
                <p className={`${activeMenu === 'cat-p' ? 'active' : ''}`}>
                  <span className="font-cat">
                    <span>พวงหรีด</span>
                    <br />
                    VIP
                  </span>
                </p>
              </Tada>
            </div>
            <div
              className="slide-menu-item cat-f"
              onClick={() => {
                onClickMenu('cat-f')
                this.onScrollTo('productF')
              }}
            >
              <Tada when={activeMenu === 'cat-f'}>
                <p className={`${activeMenu === 'cat-f' ? 'active' : ''}`}>
                  <span className="font-cat">
                    <span>พวงหรีด</span>
                    <br />
                    พัดลม
                  </span>
                </p>
              </Tada>
            </div>
          </div>
        </Fade>
      )
    else return ''
  }
}
