import React, { Component } from 'react'
import Zoom from 'react-reveal/Zoom'
import { disableBodyScroll, enableBodyScroll, clearAllBodyScrollLocks } from 'body-scroll-lock'
import { Button } from 'antd'
import Slider from 'react-slick'
import Badge from 'src/components/Badge'
import Divider from 'src/components/Divider'
import CustomModal from 'src/components/CustomModal'
import PurchaseButtonGroup from 'src/components/PurchaseButtonGroup'
// import PurchaseButtonDesktop from 'src/components/PurchaseButtonDesktop'
import { colorDefalut } from '../../../static/utils'
import ProductButtonColor from '../ProductButtonColor'
import './style.scss'
import Scroll from 'react-scroll'
const scroller = Scroll.scroller
export default class ProductPremium extends Component {
  targetRef = React.createRef()
  targetElement = null

  state = {
    isModalOpen: false,
    isShowTel: false,
    selectedProduct: {},
    focusColorA: 'other',
    runningRander: 0,
    hover: false,
    hoverColor: ''
  }

  componentDidMount() {
    this.targetElement = this.targetRef.current
  }

  componentWillUnmount() {
    clearAllBodyScrollLocks()
  }

  onOpenModal = product => {
    this.setState({ isModalOpen: true, selectedProduct: product })
    disableBodyScroll(this.targetElement)
  }

  onCloseModal = () => {
    this.setState({ isModalOpen: false, isShowTel: false })
    enableBodyScroll(this.targetElement)
  }

  onToggleTel = () => {
    this.setState({
      isShowTel: !this.state.isShowTel
    })
  }

  handleMouseOver = color => {
    this.setState({ hover: true })
    this.setState({ hoverColor: color })
  }
  handleMouseLeave = () => {
    this.setState({ hover: false })
    this.setState({ hoverColor: '' })
  }

  getColorSelect = () => {
    const colorInProduct = this.props.products || []

    let colorArray = []
    for (let index = 0; index < colorInProduct.length; index++) {
      const element = colorInProduct[index].colorTone
      for (let index = 0; index < element.length; index++) {
        const colortone = element[index]
        colorArray.push(colortone)
      }
    }

    const colorSelect = ['other'].concat(colorArray) || []
    let colorArrayOutput = []
    for (let index = 0; index < colorSelect.length; index++) {
      const color = colorSelect[index]
      if (!colorDefalut[color]) {
        colorArrayOutput.push(colorDefalut['other'])
        continue
      }
      colorArrayOutput.push(colorDefalut[color])
    }

    // Create unique color obj
    const result = colorArrayOutput.reduce((unique, o) => {
      if (!unique.some(obj => obj.nameEn === o.nameEn)) {
        unique.push(o)
      }
      return unique
    }, [])
    return result
  }

  findColorByBase = (baseColor, colorArray) => {
    let output = false
    for (let index = 0; index < colorArray.length; index++) {
      const element = colorArray[index]
      if (colorDefalut[element] && colorDefalut[element].tone.includes(baseColor)) {
        output = true
      }
    }
    return output
  }

  getSlider = products => {
    if (products) {
      if (products.length !== 0) {
        const settings = {
          dots: true,
          infinite: false,
          speed: 250,
          slidesToShow: products.length >= 4 ? 4 : products.length,
          slidesToScroll: 1,
          arrows: true,
          autoplay: false,
          customPaging: i => <div>{i + 1}</div>,
          pauseOnHover: true,
          responsive: [
            {
              breakpoint: 992,
              settings: {
                slidesToShow: 3,
                slidesToScroll: 1
              }
            },
            {
              breakpoint: 768,
              settings: {
                slidesToShow: 2,
                slidesToScroll: 2
              }
            }
          ]
        }
        return (
          <Slider {...settings}>
            {products.map((product, index) => {
              return (
                <Zoom key={index}>
                  <a
                    role="presentation"
                    className="pp-product-item product-view"
                    onClick={() => this.onOpenModal(product)}
                    onKeyDown={() => this.onOpenModal(product)}
                    id={product.id}
                  >
                    <div className="pp-product-img">
                      <img src={product.image} alt={product.id} />
                    </div>
                    <div className="pp-product-detail mobile">
                      <small>{product.id}</small>
                      <p>฿{Number(product.price).toLocaleString()}</p>
                      <div className="tag">พรีเมียม</div>
                    </div>
                    <div className="pp-product-detail desktop">
                      <small>{product.id}</small>
                      <Divider className="secondary" />
                      <div className="detail-box">
                        <div className="tag">
                          <span>พรีเมียม</span>
                        </div>
                        <p>฿{Number(product.price).toLocaleString()}</p>
                      </div>
                    </div>
                  </a>
                </Zoom>
              )
            })}
          </Slider>
        )
      }
    } else {
      return ''
    }
  }

  renderProduct = (product, index) => {
    // const offset = document.getElementById('mobile-menu').offsetHeight

    return (
      <Zoom key={this.props.runningRander + index + product.position} delay={200}>
        <a
          role="presentation"
          className="pp-product-item product-view"
          onClick={() => this.onOpenModal(product)}
          onKeyDown={() => this.onOpenModal(product)}
          id={product.id}
        >
          {product.best_seller && <Badge />}
          <div className="pp-product-img">
            <img src={product.image} alt={product.id} />
          </div>
          <div className="pp-product-detail">
            <small>{product.id}</small>
            <p>฿{Number(product.price).toLocaleString()}</p>
            <div className="see-detail-button" onClick={() => this.onOpenModal(product)}>
              <img src="https://d3u85r51ph21nf.cloudfront.net/static/icon_cursor.png" alt="คลิก" />
              <p>ดูรายละเอียด</p>
            </div>
          </div>
          {/* <div className="pp-product-detail desktop">
        <small>{product.id}</small>
        <Divider className="secondary" />
        <div className="detail-box">
          <div className="tag">
            <span>พรีเมียม</span>
          </div>
          <p>฿{Number(product.price).toLocaleString()}</p>
        </div>
      </div> */}
        </a>
      </Zoom>
    )
  }

  setColor = color => {
    this.setState({
      focusColorA: color,
      runningRander: this.state.runningRander + Math.floor(Math.random() * 100000 + 1)
    })
  }

  render() {
    const { isModalOpen, isShowTel, selectedProduct } = this.state
    const { watName, products, colorSelect } = this.props
    return (
      <div className="product-premium" id="product-p">
        <div className="container">
          <div className="line-title" />
          <div className="category-title">
            <h4>พวงหรีดพรีเมียม</h4>
          </div>
          {/* <div className="buttom-color-wrapper">
            {products &&
              products.length > 5 &&
              this.getColorSelect().map(value => {
                return (
                  <Button
                    className="buttom-color-product"
                    onMouseOver={() => {
                      this.handleMouseOver(value.nameEn)
                    }}
                    onMouseLeave={this.handleMouseLeave}
                    style={{
                      height: 'auto',
                      background: this.state.focusColorA == value.nameEn && value.colorHex,
                      borderRadius: '10px',
                      margin: '4px',
                      boxSizing: 'border-box',
                      boxShadow:
                        this.state.focusColorA == value.nameEn
                          ? '0px 5px 5px rgba(0, 0, 0, 0.25)'
                          : '0px 1px 2px rgba(0, 0, 0, 0.25)'
                    }}
                    onClick={() => {
                      this.setColor(value.nameEn)
                    }}
                  >
                    {value.nameTh}
                  </Button>
                )
              })}
          </div> */}
          {/* <div className="note-product-wrapper">
            <p>*จำนวน สี ขนาด และชนิดดอกไม้มีการผันแปรตามฤดู</p>
          </div> */}
          <div className="slider-product-wrapper">
            {products && colorSelect == 'other'
              ? products.map((product, index) => this.renderProduct(product, index))
              : products && (
                  <>
                    {products
                      .filter(e => this.findColorByBase(colorSelect, e.colorTone))
                      .map((product, index) => this.renderProduct(product, index))}
                  </>
                )}
          </div>
          {/* <div className="hide-lg"> */}
          <div className="show-lg">
            <PurchaseButtonGroup watName={watName} buttonId="below-premium-product__mobile" />
          </div>
          {/* </div> */}
          {/* <div className="show-lg">
            <PurchaseButtonDesktop buttonId="below-premium-product__desktop" />
          </div> */}
        </div>

        {isModalOpen && (
          <CustomModal
            ref={this.targetRef}
            isModalOpen={isModalOpen}
            onCloseModal={this.onCloseModal}
            selectedProduct={selectedProduct}
            watName={watName}
            onToggleTel={this.onToggleTel}
            isShowTel={isShowTel}
          />
        )}
      </div>
    )
  }
}
