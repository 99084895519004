import React, { Component } from 'react'
import Scroll from 'react-scroll'
import throttle from 'lodash.throttle'
// import Zoom from 'react-reveal/Zoom'

import Layout from 'src/components/layout'
import SEO from 'src/components/seo'
// import Hero from 'src/components/Hero'
import AllWats from 'src/components/AllWats'
import ProductPremium from 'src/components/ProductPremium'
import ProductFan from 'src/components/ProductFan'
import ProductGeneral from 'src/components/ProductGeneral'
import FAQ from 'src/components/FAQ'
import Contact from 'src/components/Contact'
import ReviewChat from 'src/components/Review/ReviewChat'
import AllLinksList from 'src/components/AllLinksList'
import ExampleReed from 'src/components/ExampleReed'
import HowToOrder from 'src/components/HowToOrder'
import SEOContent from 'src/components/SEOContent'
import OrderSteps from 'src/components/OrderSteps'
import TextValueProp from 'src/components/TextValueProp'
import ProductCategory from 'src/components/ProductCategory'
import StickyBottomButton from 'src/components/StickyBottomButton'
import Menu from 'src/components/Menu/v4'
// import AntdCustomModal from 'src/components/AntdCustomModal'
import { setCookie, getCookie } from '../../utility/cookies'
// import { colorDefalut } from '../../../static/utils'
import { CATEGORY_B, CATEGORY_A, CATEGORY_P, CATEGORY_F } from 'static/config/variables.js'
import './style.scss'
import ProductButtonColor from '../ProductButtonColor'
// import BackToTop from '../BackToTop'

import TagManager from 'react-gtm-module'
import { tagManagerArgs } from 'static/config/variables'

const Element = Scroll.Element
const scroller = Scroll.scroller
const CATEGROY_MAPPING = {
  A: 'a',
  B: 'b',
  P: 'premium',
  F: 'fan'
}

export default class WatPage extends Component {
  state = {
    products: {},
    displayProducts: {},
    isSticky: false,
    isStickyMenuMobile: false,
    orderStepsOffsetTop: null,
    navbarHeight: null,
    productAOffsetTop: null,
    productBOffsetTop: null,
    productPOffsetTop: null,
    productFOffsetTop: null,
    reviewChatOffsetTop: null,
    menuMobileOffsetTop: null,
    mobileMenuHeight: null,
    activeMenu: 'cat-a',
    justClickedMenu: false,
    isModalVisible: false,
    colorProductsFilter: [],
    focusColorA: 'other',
    runningRander: 0,
    justClickColor: false
    // renderSlide: false
  }

  componentDidMount() {
    var cookies = getCookie('tanjai')
    if (cookies) {
      this.setState({ isModalVisible: false })
    } else {
      this.setState({ isModalVisible: true })
      setCookie('tanjai', 'tanjai', 365)
    }

    TagManager.initialize(tagManagerArgs)

    if (window.location.hash === '#fan') {
      setTimeout(() => {
        const offset = document.getElementById('navbar').offsetHeight
        scroller.scrollTo('productF', {
          duration: 1500,
          delay: 100,
          smooth: false,
          offset: -offset // Scrolls to element -155 pixels up the page (minus for fix main menu) // FIXME: refactor to get heigth of the header instead
        })
      }, 1000)
    }
    if (window.location.hash === '#howto') {
      setTimeout(() => {
        const offset = document.getElementById('navbar').offsetHeight
        scroller.scrollTo('howto', {
          duration: 1500,
          delay: 100,
          smooth: false,
          offset: -offset // Scrolls to element -155 pixels up the page (minus for fix main menu) // FIXME: refactor to get heigth of the header instead
        })
      }, 1000)
    }
    if (window.location.hash === '#premium') {
      setTimeout(() => {
        const offset = document.getElementById('navbar').offsetHeight
        scroller.scrollTo('productPremium', {
          duration: 1500,
          delay: 100,
          smooth: false,
          offset: -offset // Scrolls to element -155 pixels up the page (minus for fix main menu) // FIXME: refactor to get heigth of the header instead
        })
      }, 1000)
    }
    if (window.location.hash === '#hot') {
      setTimeout(() => {
        const offset = document.getElementById('navbar').offsetHeight
        scroller.scrollTo('productB', {
          duration: 1500,
          delay: 100,
          smooth: false,
          offset: -offset // Scrolls to element -155 pixels up the page (minus for fix main menu) // FIXME: refactor to get heigth of the header instead
        })
      }, 1000)
    }
    this.getFormattedProducts()

    // NOTE: Scroll Event
    window.addEventListener('scroll', throttle(this.handleScroll, 100))

    setTimeout(() => {
      this.setState({
        orderStepsOffsetTop: document.getElementById('order-steps').offsetTop,
        productAOffsetTop: document.getElementById('product-a').offsetTop - 100,
        productBOffsetTop: document.getElementById('product-b').offsetTop - 100,
        productPOffsetTop: document.getElementById('product-p').offsetTop - 100,
        productFOffsetTop: document.getElementById('product-f').offsetTop - 100,
        reviewChatOffsetTop: document.getElementById('review-chat').offsetTop - 100
        // menuMobileOffsetTop: document.getElementById('mobile-menu').offsetTop,
      })
    }, 1000)

    this.setState({
      navbarHeight: document.getElementById('navbar').offsetHeight
    })
  }

  componentWillUnmount() {
    window.removeEventListener('scroll', throttle(this.handleScroll, 200))
  }

  showModal = () => {
    this.setState({ isModalVisible: true })
  }

  handleOk = () => {
    this.setState({ isModalVisible: false })
  }

  handleCancel = () => {
    this.setState({ isModalVisible: false })
  }

  handleScroll = () => {
    const {
      productAOffsetTop,
      productBOffsetTop,
      productPOffsetTop,
      productFOffsetTop,
      reviewChatOffsetTop,
      justClickedMenu
    } = this.state

    if (window.innerWidth <= 767) {
      // Mobile
      this.setSticky(window.pageYOffset, this.state.orderStepsOffsetTop)
      this.setStickyMenuMobile(window.pageYOffset, this.state.productAOffsetTop)
    }

    this.setState({
      orderStepsOffsetTop: document.getElementById('order-steps').offsetTop,
      productAOffsetTop: document.getElementById('product-a').offsetTop - 100,
      productBOffsetTop: document.getElementById('product-b').offsetTop - 100,
      productPOffsetTop: document.getElementById('product-p').offsetTop - 100,
      productFOffsetTop: document.getElementById('product-f').offsetTop - 100,
      reviewChatOffsetTop: document.getElementById('review-chat').offsetTop - 100
      // menuMobileOffsetTop: document.getElementById('mobile-menu').offsetTop,
    })

    if (!justClickedMenu) {
      if (window.pageYOffset > productAOffsetTop && window.pageYOffset < productBOffsetTop) {
        this.onClickMenu('cat-a')
      } else if (window.pageYOffset > productBOffsetTop && window.pageYOffset < productPOffsetTop) {
        this.onClickMenu('cat-b')
      } else if (window.pageYOffset > productPOffsetTop && window.pageYOffset < productFOffsetTop) {
        this.onClickMenu('cat-p')
      } else if (
        window.pageYOffset > productFOffsetTop &&
        window.pageYOffset < reviewChatOffsetTop
      ) {
        this.onClickMenu('cat-f')
      } else {
        this.onClickMenu('')
      }
    }
  }

  onClickMenu = menu => {
    clearTimeout(this.timer)
    this.setState({
      activeMenu: menu,
      justClickedMenu: true
    })

    this.timer = setTimeout(() => {
      this.setState({
        justClickedMenu: false
      })
    }, 1600)
  }

  setSticky = (windowPageOffset, sectionHeight) => {
    if (windowPageOffset > sectionHeight - 150) {
      if (!this.state.isSticky) {
        this.setState({
          isSticky: true
        })
      }
    }
  }

  setStickyMenuMobile = (windowPageOffset, sectionHeight) => {
    if (windowPageOffset > sectionHeight) {
      if (!this.state.isStickyMenuMobile) {
        this.setState({
          isStickyMenuMobile: true
          // mobileMenuHeight: document.getElementById('mobile-menu').offsetHeight
        })
      }
    }
  }

  getFormattedProducts = () => {
    const products = this.props.product.edges
    const formattedProducts = products.map(product => {
      return {
        ...product.node.frontmatter,
        description: product.node.rawMarkdownBody
      }
    })
    const _products = {
      a: this.getProductsByCategory(formattedProducts, 'A'),
      b: this.getProductsByCategory(formattedProducts, 'B'),
      premium: this.getProductsByCategory(formattedProducts, 'P'),
      fan: this.getProductsByCategory(formattedProducts, 'F')
    }
    this.setState({ products: _products }, () => {
      this.getOwnedProducts()
    })
  }

  getProductsByCategory = (products, category) => {
    return products
      .filter(product => product.category === category)
      .map(item => {
        // NOTE: Override product from base product
        // console.log(item)
        if (item.selectedProduct) {
          const _category = CATEGROY_MAPPING[category]
          const stateProducts = [...this.state.products[_category]]

          const filteredProduct = stateProducts.filter(i => {
            return i.id === item.selectedProduct
          })
          // console.log('filteredProduct :', filteredProduct)
          return {
            ...filteredProduct[0],
            price: item.price && item.price !== '' ? item.price : filteredProduct[0].price,
            position:
              item.position !== 0 || item.position === ''
                ? item.position
                : filteredProduct[0].position,
            isShow: item.isShow === true ? true : filteredProduct[0].isShow,
            badge: item.best_seller,
            image: filteredProduct[0].imageS3
              ? filteredProduct[0].imageS3
              : filteredProduct[0].image
          }
        } else {
          // console.log('use id')
          return {
            ...item,
            image: item.imageS3 ? item.imageS3 : item.image
          }
        }
      })
      .sort((a, b) => {
        return a.position - b.position
      })
  }

  getOwnedProducts = () => {
    const wat = this.props.wat.frontmatter

    if (wat.useOwnProducts) {
      const _products = {
        a:
          wat.aSection && wat.aSection.isShowSection
            ? this.getProductsByCategory(wat.aSection.products, 'A').filter(
                product => product.isShow
              )
            : [],
        b:
          wat.bSection && wat.bSection.isShowSection
            ? this.getProductsByCategory(wat.bSection.products, 'B').filter(
                product => product.isShow
              )
            : [],
        premium:
          wat.premiumSection && wat.premiumSection.isShowSection
            ? this.getProductsByCategory(wat.premiumSection.products, 'P').filter(
                product => product.isShow
              )
            : [],
        fan:
          wat.fSection && wat.fSection.isShowSection
            ? this.getProductsByCategory(wat.fSection.products, 'F').filter(
                product => product.isShow
              )
            : []
      }
      this.setState({ products: _products, displayProducts: _products }, () => {})
    } else {
      const stateProducts = { ...this.state.products }
      const tmp = {
        a: stateProducts.a.filter(product => product.isShow),
        b: stateProducts.b.filter(product => product.isShow),
        premium: stateProducts.premium.filter(product => product.isShow),
        fan: stateProducts.fan.filter(product => product.isShow)
      }
      this.setState({ products: tmp, displayProducts: tmp }, () => {})
    }
  }

  validateColorFilterButton = (isSticky, group, name) => {
    const allowListGroup = ['bangkok']
    const allowListName = ['เชียงใหม่ทดสอบ']
    return (allowListGroup.includes(group) || allowListName.includes(name)) && isSticky
  }
  getPaddingForStickyMenu = () => {
    const { isStickyMenuMobile, navbarHeight } = this.state
    if (isStickyMenuMobile) {
      return 60
    } else {
      return navbarHeight
    }
  }

  render() {
    const {
      displayProducts,
      isSticky,
      activeMenu,
      isStickyMenuMobile,
      focusColorA,
      runningRander
    } = this.state
    const { wat, isHomepage, watsList } = this.props
    const {
      frontmatter: {
        buttonLabel,
        name,
        name2,
        seoContent,
        metaTitle,
        metaDescription,
        faq,
        useOwnProducts,
        aSection,
        bSection,
        premiumSection,
        fSection
      }
    } = wat

    // console.log(wat)
    return (
      <Layout
        buttonLabel={buttonLabel}
        watName={name}
        productsDetail={wat.frontmatter}
        onClickMenu={this.onClickMenu}
        activeMenu={activeMenu}
      >
        <div style={{ paddingTop: this.getPaddingForStickyMenu() }}>
          <SEO
            title="Home"
            placeName={name}
            metaDescription={metaDescription}
            metaTitle={metaTitle}
          />
          <StickyBottomButton isSticky={isSticky} />
          {/* {renderSlide && (
            <CustomCarousel watName1={name} watName2={name2} isHomepage={isHomepage} />
          )} */}
          {/* <ValueProp watName1={name} watName2={name2} isHomepage={isHomepage} /> */}
          <HowToOrder watName1={name} watName2={name2} seoContent={seoContent} />
          {/* <Hero watName1={name} watName2={name2} /> */}
          {/* <Delivery watName1={name} isHomepage={isHomepage} /> */}
          <ExampleReed />
          {/* <Trusted /> */}
          {/* <Review /> */}
          {/* <BackToTop isSticky={isSticky} /> */}
          {isHomepage && <AllWats watsList={watsList} />}
          <Element name="howto">
            <OrderSteps />
          </Element>
          <TextValueProp watName1={name} />
          {useOwnProducts ? (
            <>
              <ProductButtonColor
                key="product-color-useproducts"
                colorProduct={['other', 'hot', 'cool', 'smart']}
                onSelect={v => {
                  this.setState({
                    focusColorA: v,
                    runningRander: this.state.runningRander + Math.floor(Math.random() * 100 + 1)
                  })
                }}
                onClick={this.onClickMenu}
                id={activeMenu}
                isStickyMenuMobile={this.validateColorFilterButton(
                  isStickyMenuMobile,
                  wat.frontmatter.group,
                  wat.frontmatter.name
                )}
              />
              <ProductCategory
                aPriceRange={`${aSection.categoryName}฿`}
                bPriceRange={`${bSection.categoryName}฿`}
                fPriceRange={`${fSection.categoryName}฿`}
              />
              <Menu
                isStickyMenuMobile={isStickyMenuMobile}
                onClickMenu={this.onClickMenu}
                activeMenu={activeMenu}
              />
              {aSection && aSection.isShowSection && (
                <Element name="productA">
                  <ProductGeneral
                    watName={`${name}`}
                    products={displayProducts.a}
                    categoryTitle={`พวงหรีดราคาประหยัด`}
                    subTitle={`พวงหรีดดอกไม้สดราคาพิเศษ ช่วงราคา`}
                    priceRange={`฿${aSection.categoryName}`}
                    buttonId="below-catA-product"
                    id="product-a"
                    colorSelect={focusColorA}
                    runningRander={runningRander}
                  />
                </Element>
              )}
              {bSection && bSection.isShowSection && (
                <Element name="productB">
                  <ProductGeneral
                    watName={`${name}`}
                    products={displayProducts.b}
                    categoryTitle={`พวงหรีดมาตรฐาน`}
                    subTitle={`พวงหรีดดอกไม้สดแบบมาตรฐาน ช่วงราคา`}
                    priceRange={`฿${bSection.categoryName}`}
                    buttonId="below-catB-product"
                    id="product-b"
                    colorSelect={focusColorA}
                    runningRander={runningRander}
                  />
                </Element>
              )}
              {premiumSection && premiumSection.isShowSection && (
                <Element name="productPremium">
                  <ProductPremium
                    watName={`${name}`}
                    products={displayProducts.premium}
                    categoryTitle={`แบบ${premiumSection.categoryName}`}
                    colorSelect={focusColorA}
                    runningRander={runningRander}
                  />
                </Element>
              )}
              {fSection && fSection.isShowSection && (
                <Element name="productF">
                  <ProductFan
                    watName={`${name}`}
                    products={displayProducts.fan}
                    priceRange={`฿${fSection.categoryName}`}
                    colorSelect={focusColorA}
                    runningRander={runningRander}
                  />
                </Element>
              )}
            </>
          ) : (
            <>
              <ProductCategory
                aPriceRange={`${CATEGORY_A}฿`}
                bPriceRange={`${CATEGORY_B}฿`}
                fPriceRange={`${CATEGORY_F}฿`}
              />
              <ProductButtonColor
                key="product-color-not-useproducts"
                colorProduct={['other', 'hot', 'cool', 'smart']}
                onSelect={v => {
                  this.setState({
                    focusColorA: v,
                    runningRander: this.state.runningRander + Math.floor(Math.random() * 100 + 1)
                  })
                }}
                id={activeMenu}
                onClick={this.onClickMenu}
                isStickyMenuMobile={this.validateColorFilterButton(
                  isStickyMenuMobile,
                  wat.frontmatter.group,
                  wat.frontmatter.name
                )}
              />
              <Menu
                isStickyMenuMobile={isStickyMenuMobile}
                onClickMenu={this.onClickMenu}
                activeMenu={activeMenu}
              />
              <Element name="productA">
                <ProductGeneral
                  watName={`${name}`}
                  products={displayProducts.a}
                  categoryTitle={`พวงหรีดราคาประหยัด`}
                  subTitle={`พวงหรีดดอกไม้สดราคาพิเศษ ช่วงราคา`}
                  priceRange={`฿${CATEGORY_A}`}
                  buttonId="below-catA-product"
                  id="product-a"
                  colorSelect={focusColorA}
                  runningRander={runningRander}
                />
              </Element>
              <Element name="productB">
                <ProductGeneral
                  watName={`${name}`}
                  products={displayProducts.b}
                  categoryTitle={`พวงหรีดมาตรฐาน`}
                  subTitle={`พวงหรีดดอกไม้สดแบบมาตรฐาน ช่วงราคา`}
                  priceRange={`฿${CATEGORY_B}`}
                  buttonId="below-catB-product"
                  id="product-b"
                  colorSelect={focusColorA}
                  runningRander={runningRander}
                />
              </Element>
              <Element name="productPremium">
                <ProductPremium
                  watName={`${name}`}
                  products={displayProducts.premium}
                  categoryTitle={`แบบ${CATEGORY_P}`}
                  colorSelect={focusColorA}
                  runningRander={runningRander}
                />
              </Element>
              <Element name="productF">
                <ProductFan
                  watName={`${name}`}
                  products={displayProducts.fan}
                  priceRange={`฿${CATEGORY_F}`}
                  colorSelect={focusColorA}
                  runningRander={runningRander}
                />
              </Element>
            </>
          )}
          <ReviewChat />
          <FAQ watName={`${name}`} faq={faq} />
          <Element name="serviceAreas">
            <AllLinksList watsList={watsList} />
          </Element>
          <Contact />
          <SEOContent content={seoContent} />
        </div>
        {/* <AntdCustomModal isModalOpen={this.state.isModalVisible} onCloseModal={this.handleOk} /> */}
      </Layout>
    )
  }
}
