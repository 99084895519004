import jwt from 'jsonwebtoken'
import { JWT_KEY } from 'static/config'

const scretKey = JWT_KEY

export function setCookie(name, value, days) {
  var token = jwt.sign({ name: value }, scretKey)
  var expires = ''
  if (days) {
    var date = new Date()
    date.setTime(date.getTime() + days * 24 * 60 * 60 * 1000)
    expires = '; expires=' + date.toUTCString()
  }
  document.cookie = name + '=' + (token || '') + expires + '; path=/'
}

export function getCookie(name) {
  var nameEQ = name + '='
  var ca = document.cookie.split(';')
  for (var i = 0; i < ca.length; i++) {
    var c = ca[i]
    while (c.charAt(0) === ' ') c = c.substring(1, c.length)

    //decode
    if (c.indexOf(nameEQ) === 0) return jwt.verify(c.substring(nameEQ.length, c.length), scretKey)
  }
  return null
}

export function eraseCookie(name) {
  document.cookie = name + '=; Path=/; Expires=Thu, 01 Jan 1970 00:00:01 GMT;'
}
