import React from 'react'
import styled from 'styled-components'
import './style.scss'

const CustomBadge = styled.div`
  position: absolute;
  left: -1px;
  top: -1px;
`

const FlagGreenEle = styled.img`
  width: 3em;
  height: 70px;
`

const BadgeText = styled.span`
  position: absolute;
  left: 50%;
  top: 40%;
  transform: translate(-50%, -50%);
  color: #fff;
  width: 100%;
  font-size: 18px;
`

const Star = styled.img`
  width: 20px;
  height: 5px;
  position: absolute;
  left: 50%;
  transform: translate(-50%, 0%);
  bottom: 14px;
`

const Badge = () => {
  return (
    <CustomBadge>
      <img
        className="flag-badge"
        src="https://d3u85r51ph21nf.cloudfront.net/static/flag_red.png"
        alt="ขายดี"
      />
      <span className="text-badge">ขายดี</span>
      <img
        className="star-badge"
        src="https://d3u85r51ph21nf.cloudfront.net/static/starx3.png"
        alt="ขายดี"
      />
    </CustomBadge>
  )
}

export default Badge
