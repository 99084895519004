import React, { Component } from 'react'
import Fade from 'react-reveal/Fade'
import HeadShake from 'react-reveal/HeadShake'

import './style.scss'

export default class HowToOrder extends Component {
  render() {
    return (
      <div className="order-steps" id="order-steps">
        <div className="order-steps-box">
          <Fade left cascade delay={200} duration={800}>
            <div className="mobile">
              <h4>สั่งพวงหรีด</h4>
              <p>ง่ายๆ 3 ขั้นตอน</p>
            </div>
            <div className="desktop">
              <h4>สั่งพวงหรีด ง่ายๆ 3 ขั้นตอน</h4>
            </div>
            <a href="https://lin.ee/6Bs20SI?tanjai=A8" target="_blank" rel="noopener noreferrer">
              <div className="desktop how-to-desktop">
                <img
                  className="img-responsive"
                  src="https://d3u85r51ph21nf.cloudfront.net/static/how01_desktop.png"
                  alt="แคปรูป แอดไลน์ แจ้งรายละเอียด"
                />
                <img
                  className="img-responsive"
                  src="https://d3u85r51ph21nf.cloudfront.net/static/how02_desktop.png"
                  alt="แคปรูป แอดไลน์ แจ้งรายละเอียด"
                />
                <img
                  className="img-responsive"
                  src="https://d3u85r51ph21nf.cloudfront.net/static/how03_desktop.png"
                  alt="แคปรูป แอดไลน์ แจ้งรายละเอียด"
                />
              </div>
              <img
                className="how-to img-responsive mobile"
                src="https://d3u85r51ph21nf.cloudfront.net/static/how_to_order.png"
                alt="แคปรูป แอดไลน์ แจ้งรายละเอียด"
              />
            </a>
          </Fade>
          <HeadShake delay={1000}>
            <a
              className="desktop"
              href="https://lin.ee/6Bs20SI?tanjai=A9"
              target="_blank"
              rel="noopener noreferrer"
              id="navbar-line"
              title="@tanjai.co"
            >
              <div className="line-button">
                <img
                  src="https://d3u85r51ph21nf.cloudfront.net/premium/static/icon_line_white.png"
                  alt="@tanjai.co"
                />
                <span>ปรึกษา + แชท คลิก!</span>
              </div>
            </a>
          </HeadShake>
        </div>
      </div>
    )
  }
}
