import React, { useState, useEffect } from 'react'
import Fade from 'react-reveal/Fade'
import propTypes from 'prop-types'

import { getTelFormatWithCountryCode } from 'static/utils'
import { TEL } from 'static/config/variables'
import BackToTOop from '../BackToTop'
import './style.scss'

const StickyBottomButton = props => {
  const [stlyeContact, setStlyeContact] = useState(false)
  const { isSticky } = props
  useEffect(() => {
    if (typeof window !== 'undefined') {
      scrollAnimation()
    }
  }, [])

  const scrollAnimation = () => {
    var prevScrollpos = window.pageYOffset
    window.onscroll = function() {
      var currentScrollPos = window.pageYOffset
      if (prevScrollpos > currentScrollPos) {
        setStlyeContact(false)
      } else {
        setStlyeContact(true)
      }
      prevScrollpos = currentScrollPos
    }
  }

  if (isSticky)
    return (
      <Fade duration={500}>
        <div className="bottom-button mobile" style={{ bottom: stlyeContact ? '0px' : '-120px' }}>
          <div>
            <BackToTOop isSticky={isSticky} animetion={stlyeContact} />
            {/* Call Button */}
            <div className="action-button-wrapper">
              <div className="action-button brown">
                <img
                  src="https://d3u85r51ph21nf.cloudfront.net/premium/static/icon_telephone_white.png"
                  alt={TEL}
                />
                <a
                  id="header-call-mobile"
                  href={`tel:${getTelFormatWithCountryCode(TEL)}`}
                  title={TEL}
                >
                  โทรสอบถาม
                </a>
              </div>

              {/* Line Button */}
              <div className="action-button green">
                <img
                  src="https://d3u85r51ph21nf.cloudfront.net/premium/static/icon_line_white.png"
                  alt="@tanjai.co"
                />
                <a
                  href="https://lin.ee/6Bs20SI?tanjai=B4"
                  target="_blank"
                  rel="noopener noreferrer"
                  id="navbar-line"
                  title="@tanjai.co"
                >
                  <span>สั่งซื้อพวงหรีด</span>
                </a>
              </div>
            </div>

            <p>*จำนวน สี ขนาด และชนิดดอกไม้มีการผันแปรตามฤดู</p>
          </div>
        </div>
      </Fade>
    )
  else {
    return ''
  }
}

StickyBottomButton.defaultProps = {
  isSticky: null
}

StickyBottomButton.propTypes = {
  isSticky: propTypes.bool
}

export default StickyBottomButton
