import React, { useEffect, useState } from 'react'
import { BackTop } from 'antd'
import { UpOutlined } from '@ant-design/icons'
import propTypes from 'prop-types'
import './style.scss'
import Fade from 'react-reveal/Fade'
import Scroll from 'react-scroll'
const scroller = Scroll.scroller
const BackToTop = props => {
  //   const [animetion, setAnimetion] = useState(false)
  const { isSticky, animetion } = props
  useEffect(() => {
    // scrollAnimation()
  }, [])

  const onScrollTo = ele => {
    const offset = document.getElementById('mobile-menu').offsetHeight
    scroller.scrollTo(ele, {
      duration: 1500,
      // delay: 100,
      smooth: 'easeInOutQuad',
      offset: -offset // Scrolls to element -155 pixels up the page (minus for fix main menu) // FIXME: refactor to get heigth of the header instead
    })
  }

  if (isSticky && window.pageYOffset > document.getElementById('product-a').offsetTop) {
    return (
      <Fade duration={500}>
        {/* <Fade duration={500} when={animetion}></Fade> */}
        <div
          className="back-top-sticky"
          style={{ bottom: animetion ? '120px' : '-120px' }}
          //   style={{ display: !animetion && 'none' }}
          key="back-top"
          onClick={() => onScrollTo('product-a')}
        >
          <UpOutlined
            className="site-back-top"
            style={{ color: '#a1977f', fontSize: '25px', fontWeight: 'bold' }}
          />
        </div>
      </Fade>
    )
  } else return ''
}

BackToTop.defaultProps = {
  isSticky: null,
  animetion: false
}

BackToTop.propTypes = {
  isSticky: propTypes.bool,
  animetion: propTypes.bool
}

export default BackToTop
