import React, { Component } from 'react'
import Fade from 'react-reveal/Fade'
import HeadShake from 'react-reveal/HeadShake'

// import Divider from 'src/components/Divider'

import './style.scss'

export default class AllWats extends Component {
  state = {
    isShowMore: false,
    displayWatsList: [],
    allWatsList: []
  }

  componentDidMount() {
    this.getFormattedList()
  }

  getFormattedList = () => {
    const watsArray = this.props.watsList.edges
      .map(wat => {
        return {
          name: wat.node.frontmatter.name,
          name2: wat.node.frontmatter.name2,
          landingPage: wat.node.frontmatter.landingPage,
          path: wat.node.frontmatter.path
        }
      })
      .filter(item => item.landingPage && item.landingPage.isShowOnLandingPage)
      .sort((a, b) => Number(a.landingPage.position) - Number(b.landingPage.position))
    this.setState({
      allWatsList: watsArray
    })
    this.getDisplayList(watsArray)
  }

  getDisplayList = watsList => {
    if (watsList.length > 10) {
      let tmp = watsList.slice(0, 10)
      this.setState({ displayWatsList: tmp, isShowMore: true })
    } else {
      this.setState({ displayWatsList: watsList })
    }
  }

  onViewMore = () => {
    this.setState({ displayWatsList: this.state.allWatsList, isShowMore: false })
  }

  render() {
    const { allWatsList, displayWatsList, isShowMore } = this.state
    return (
      <div className="wats">
        <div className="container">
          <div className="wats-header">
            <div className="title">
              <h2>สั่งพวงหรีดวัดดัง</h2>
            </div>
            {/* <Divider /> */}
            <div>
              <p>
                พร้อมส่งถึงวัดในวันเดียวกัน <br /> ส่งฟรีวัดดังในกรุงเทพ
              </p>
            </div>
          </div>
          {/* Mobile */}
          <div className="wats-list mobile">
            {displayWatsList &&
              displayWatsList.map((item, index) => {
                return (
                  <Fade key={index} bottom>
                    <a href={`${item.path}`} className="wat-item" key={index} title={item.name}>
                      <span>{item.name}</span>
                    </a>
                  </Fade>
                )
              })}
          </div>
          {isShowMore && (
            <div className="more d-md-none">
              <HeadShake delay={500}>
                <p onClick={this.onViewMore}>
                  ดูวัดเพิ่มเติม{' '}
                  <img
                    src="https://d3u85r51ph21nf.cloudfront.net/static/arrow_gold.png"
                    alt="arrow down"
                    width="11"
                    height="7"
                  />
                </p>
              </HeadShake>
            </div>
          )}
          {/* Mobile */}

          {/* Desktop */}
          <div className="wats-list desktop">
            {allWatsList &&
              allWatsList.map((item, index) => {
                return (
                  <Fade key={index} bottom>
                    <a href={`${item.path}`} className="wat-item" key={index} title={item.name}>
                      <span>{item.name}</span>
                    </a>
                  </Fade>
                )
              })}
          </div>
          {/* Desktop */}

          <Fade delay={200}>
            <div className="text-center">
              <div className="button-group-wrapper">
                <div className="button-group">
                  <div className="d-inline-block mr-2">
                    <p>วัดอื่นๆ นอกเหนือรายชื่อด้านบน โปรดติดต่อเรา</p>
                    <HeadShake delay={1000}>
                      <a
                        href="https://lin.ee/6Bs20SI?tanjai=A1"
                        target="_blank"
                        rel="noopener noreferrer"
                        alt="@tanjai.co"
                        title="@tanjai.co"
                        className="button"
                        id="all-wats-button"
                      >
                        แจ้งชื่อวัดอื่นๆ กับเจ้าหน้าที่ คลิกเลย!
                      </a>
                    </HeadShake>
                  </div>
                  <a
                    href="https://lin.ee/6Bs20SI?tanjai=A2"
                    target="_blank"
                    rel="noopener noreferrer"
                    alt="@tanjai.co"
                    id="d-all-wats-qr"
                    title="@tanjai.co"
                  >
                    <img
                      src="https://d3u85r51ph21nf.cloudfront.net/static/qr.png"
                      alt="@tanjai.co"
                    />
                  </a>
                </div>
              </div>
              <p className="below-text">
                พิมพ์ค้นหาเพิ่มเพื่อน{' '}
                <a
                  href="https://lin.ee/6Bs20SI?tanjai=A3"
                  target="_blank"
                  rel="noopener noreferrer"
                  alt="@tanjai.co"
                  id="d-all-wats"
                  title="@tanjai.co"
                >
                  @tanjai.co
                </a>{' '}
                หรือสแกน QR Code เพื่อติดต่อเรา
              </p>
            </div>
          </Fade>
        </div>
      </div>
    )
  }
}

AllWats.propTypes = {}
